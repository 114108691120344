import React from 'react';
import { Divider, Paper, Typography } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import TrainIcon from '@mui/icons-material/Train';
import moment from 'moment';
import { getWagonClass } from '../../../common/Utils';

export const StationTicketCard = ({stationTiciketCard}) => ( 
    <Paper id={"selectedStationTicketCard"} className={'paperCards'} style={{padding:"0"}} >
        <div id={"selectedStationTicketCard-date"} style={{padding:"16px 16px 8px 16px", background:"#F2F2F2", borderRadius:"8px 8px 0 0", display:"flex", alignItems:"center"}} >
            <TrainIcon style={{color:"#999", fontSize:"18px", marginRight:"6px"}} />
            <Typography id={"selectedStationTicketCard-date-value"} style={{fontWeight:"500", fontSize:"12px", color:"#555"}}>{moment(stationTiciketCard.departdate,"YYYY-MM-DD").format("ddd, DD MMM YYYY")}</Typography>
        </div>
        <div style={{margin:"8px 0", padding:"0 16px"}}>
            <div id={"selectedStationTicketCard-name"} style={{display:"flex", alignItems:"center"}} >
                <Typography id={"selectedStationTicketCard-name-start"} style={{fontSize:"14px", fontWeight:"bold", color:"#1A1A1A"}}>{`${stationTiciketCard.originStation} (${stationTiciketCard.stasiunOrginCode})`}</Typography>
                <ArrowForwardIcon fontSize='18' style={{margin:"0 5px"}} className={"iconArrowInSelectInput"} />
                <Typography id={"selectedStationTicketCard-name-end"} style={{fontSize:"14px", fontWeight:"bold", color:"#1A1A1A"}}>{`${stationTiciketCard.destinationStation} (${stationTiciketCard.stasiunDestCode})`}</Typography>
            </div>
            <div id={"selectedStationTicketCard-time"} style={{display:"flex", alignItems:"center", marginTop:"4px"}} >
                <Typography id={"selectedStationTicketCard-time-start"} style={{fontSize:"12px", color:"#555"}}>{moment(stationTiciketCard.departureTime,"HHm").format("HH:mm")}</Typography>
                <Divider style={{width:"1vw", margin:"0 5px"}} />
                <Typography id={"selectedStationTicketCard-time-end"} style={{fontSize:"12px", color:"#555"}}>{moment(stationTiciketCard.arrivalTime,"HHm").format("HH:mm")}</Typography>
            </div>
        </div>
        <div id={"selectedStationTicketCard-class"} style={{display:"flex", alignItems:"center", padding:"0 16px 16px 16px"}} >
            <Typography id={"selectedStationTicketCard-class-start"} style={{fontSize:"12px", color:"#555"}}>{`${stationTiciketCard.trainName} (${stationTiciketCard.trainNumber})`}</Typography>
            <Divider style={{width:"1vw", margin:"0 5px"}} />
            <Typography id={"selectedStationTicketCard-class-end"} style={{fontSize:"12px", color:"#555"}}>{getWagonClass(stationTiciketCard.wagonClass, stationTiciketCard.subClass)}</Typography>
        </div>
    </Paper>
);