/* eslint-disable */
import React, { useEffect, useState } from 'react';
import Indicators from './Indicators';

import ThreeByTwo from './ThreeByTwo';
import TwoByTwo from './TwoByTwo';
import OneByTwo from './OneByTwo';
import OneByOne from './OneByOne';
import PropTypes from "prop-types";

const SeatLayout = ({selectedClass, setSelectedClass, setSeatChange, currentSelectedClassLayout}) => {

    const [layout, setLayout] = useState('')
    
    // SET LAYOUT
    useEffect(() => {
        const KAI = JSON.parse(localStorage.getItem('KAI'));

        const seatClass = KAI.seatList.currentSeat.seat.split(',')[0];
        const classSeatDataList = KAI.seatList.list.classSeatData;

        let currentLayout = '';
        
        classSeatDataList.map((obj) => {
            const key = Object.keys(obj)[0];
            if(key === seatClass) currentLayout = obj[key].layout;
        });
        setLayout(currentLayout.toUpperCase());
    }, []);

    const getLayout = (layout) => {
        switch (layout) {
            case "3X2":
                return (
                    <ThreeByTwo 
                        selectedClass={selectedClass}
                        setSelectedClass={setSelectedClass}
                        setSeatChange={setSeatChange}
                        currentSelectedClassLayout={currentSelectedClassLayout}
                    />  
                );
            
            case "2X2":
                return (
                    <TwoByTwo 
                        selectedClass={selectedClass}
                        setSelectedClass={setSelectedClass}
                        setSeatChange={setSeatChange}
                        currentSelectedClassLayout={currentSelectedClassLayout}
                    /> 
                );
            
            case "1X2":
                return (
                    <OneByTwo
                        selectedClass={selectedClass}
                        setSelectedClass={setSelectedClass}
                        setSeatChange={setSeatChange}
                        currentSelectedClassLayout={currentSelectedClassLayout} 
                    />
                );
            
            case "1X1":
                return (
                    <OneByOne
                        selectedClass={selectedClass}
                        setSelectedClass={setSelectedClass}
                        setSeatChange={setSeatChange}
                        currentSelectedClassLayout={currentSelectedClassLayout} 
                    />
                );
        
            default:
                return '';
        }
    }
    
    return (
        <div> 
            { getLayout(layout) }
            <Indicators selectedClass={selectedClass} />
        </div>
    )
}

SeatLayout.propTypes = {
    selectedClass: PropTypes.string,
    setSelectedClass: PropTypes.func,
    setSeatChange: PropTypes.func,
    currentSelectedClassLayout: PropTypes.object,
}

export default SeatLayout;
